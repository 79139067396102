import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-first-main-slide',
  templateUrl: './first-main-slide.component.html',
  styleUrls: ['./first-main-slide.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstMainSlideComponent implements OnInit {
  @Input()
  text!: string;
  @Input()
  title!: string;
  @Input()
  imgUrl?: string;

  backgroundImage?: string;
  constructor() {}

  ngOnInit(): void {
    this.backgroundImage = `url(${this.imgUrl})`;
  }
}
