import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-talk-about-dementia-in-the-media',
  templateUrl: './how-to-talk-about-dementia-in-the-media.component.html',
  styleUrls: ['./how-to-talk-about-dementia-in-the-media.component.css'],
})
export class HowToTalkAboutDementiaInTheMediaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
