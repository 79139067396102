export const subscribeArray = [
  {
    id: '1',
    bankName: 'monobank',
    logo: './assets/images/banksLogo/monobank.png',
    requsites: '',
  },
  {
    id: '2',
    bankName: 'patreon',
    logo: './assets/images/banksLogo/patreon.png',
    requsites: '',
  },
  {
    id: '3',
    bankName: 'privatbank',
    logo: './assets/images/banksLogo/privatbank.png',
    requsites: '',
  },
];
