<div class="w-full bg-secondary-base overflow-hidden h-170 md:h-137.5 xl:h-162">
  <div
    class="bg-no-repeat bg-contain w-full h-full bg-[top_-40px_right_-55px] bg-[length:470px_413px] bg-[url('assets/images/bannerPicture/tabletSecondBanner.png')] md:bg-[url('assets/images/bannerPicture/tabletSecondBanner.png')] xl:bg-[url('assets/images/bannerPicture/secondBannerPicture.png')] md:bg-[top_-86px_right_-142px] md:bg-[length:808px_710px] xl:bg-contain xl:bg-[top_0px_right_3.5rem]">
    <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
      <div
        class="flex flex-col h-full pb-12.5 md:pb-33 xl:pb-35 items-start justify-end text-gray-dark w-full sm:w-125 xl:w-119 px-4.5 md:px-0 md:pl-15 xl:pl-0">
        <h3 class="text-h2-heading md:text-h1-heading mb-5">
          {{ title | uppercase }}
        </h3>
        <div class="md:w-55 w-full mt-5">
          <ng-content select=".btn"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
