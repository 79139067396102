<div class="w-full h-170 md:h-137.5 xl:h-162 overflow-hidden">
  <div
    class="bg-no-repeat bg-center sm:bg-right md:bg-center bg-cover h-full w-full bg-[url('assets/images/bannerPicture/thirdBannerMobile.png')] sm:bg-[url('assets/images/bannerPicture/thirdBannerTablet.png')] xl:bg-[url('assets/images/bannerPicture/thirdBanner.png')]">
    <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
      <div
        class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-133 xl:w-120 px-4 md:pl-15 xl:pl-0 md:px-0">
        <h3
          class="text-h2-heading md:text-h1-heading md:mb-2.5 text-gray-white">
          {{ title | uppercase }}
        </h3>
        <div class="flex flex-row justify-start w-55 mt-5 md:mt-8">
          <div
            class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
            <a href="https://www.instagram.com/nezabutni_/" target="_blank"
              ><svg
                width="24px"
                height="24px"
                icon="instagram"
                iconCollection="social-icons-sprite"
                class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
            ></a>
          </div>
          <div
            class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
            <a href="https://www.facebook.com/nezabutni.org" target="_blank"
              ><svg
                width="16px"
                height="29px"
                icon="facebook"
                iconCollection="social-icons-sprite"
                class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
            ></a>
          </div>
          <div
            class="group/social flex justify-center hover:fill-secondary-additional items-center w-12 h-12 bg-white rounded-full cursor-pointer">
            <a href="https://t.me/NezabutniCF" target="_blank"
              ><svg
                width="30px"
                height="22px"
                icon="telegram"
                iconCollection="social-icons-sprite"
                class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
