import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-third-main-slide',
  templateUrl: './third-main-slide.component.html',
  styleUrls: ['./third-main-slide.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThirdMainSlideComponent implements OnInit {
  @Input()
  text!: string;
  @Input()
  title!: string;
  constructor() {}

  ngOnInit(): void {}
}
